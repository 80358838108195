import header from './class/header.js';
import otherHeader from './class/otherHeader.js';
import Lottie from './class/lottie.js';
import Sales from './class/sales.js';
import Store from './class/store.js';
import Aos from './class/Aos';
import General from './class/general.js';
import Reveale from './class/reveale.js';

export default class Main {
    constructor() {
        new Lottie()
        new General()
        new Aos()

        if(document.querySelector('#tp')){
          new header()
          new Reveale()
        }
        if(document.querySelector('#other')){
          new otherHeader()
        }
        if(document.querySelector('#sa')){
          new Sales()
        }
        if(document.querySelector('#st')){
          new Store()
        }

      }
  }

  window.addEventListener('DOMContentLoaded',() => {
    new Main()
  });
