export default class mq {
  constructor() {
    $(function () {
      setTimeout(function(){
        $(".revealItem_1").addClass("revealActive")
      },1000);
      setTimeout(function(){
        $(".revealItem_2").addClass("revealActive")
      },1200);

      $('html').fadeOut(0);
      $(".l-mv svg").hide();
      $("iframe").hide();

      setTimeout(function(){
        $('html').fadeIn(100);
        $(".l-mv svg").show();
        $("iframe").show();
      },300);

      $('.is_sp .acBtn').click(function() {
        $('.is_sp .acBox').slideToggle(300);
        $('.is_sp .ac_icon').toggleClass('ac_icon_on');
      })

      $('#sp_menu').click(function() {
        $("#menu_open").slideToggle(500);
        $(this).text(function(i, text){
          return text === "CLOSE" ? "MENU" : "CLOSE";
        })
      })
      $('#menu_open a').click(function(event) {
        event.preventDefault();
        var linkUrl = $(this).attr('href');
        $("#menu_open").slideToggle(500);
        function action() {
          location.href = linkUrl;
        }
        setTimeout(action,400);
      })
    });
    $('a[href^="#"]').click(function(){
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });

    $(window).scroll(function() {
          if($(this).scrollTop() > 300) { 
              $("#totop").fadeIn(); 
          } else {
              $("#totop").fadeOut();
          }
      });

      $(".menu_link").hover(function() {
        $("#overwrap_black").stop().fadeIn(150);
        }, function () {
          $("#overwrap_black").stop().fadeOut(150);
        });
  }

}