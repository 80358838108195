export default class Reveale {
  constructor() {
    (function () {
        
      setTimeout(label_01, 10);
      function label_01() {
        var scrollElemToWatch_1 = document.getElementById('label_01'),
          watcher_1 = scrollMonitor.create(scrollElemToWatch_1, -100),
          rev1 = new RevealFx(scrollElemToWatch_1, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 300,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_1.enterViewport(function () {
          rev1.reveal();
          watcher_1.destroy();
        });
      }

      setTimeout(label_02, 10);
      function label_02() {
        var scrollElemToWatch_2 = document.getElementById('label_02'),
          watcher_2 = scrollMonitor.create(scrollElemToWatch_2, -100),
          rev2 = new RevealFx(scrollElemToWatch_2, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 300,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_2.enterViewport(function () {
          rev2.reveal();
          watcher_2.destroy();
        });
      }

      setTimeout(label_03, 10);
      function label_03() {
        var scrollElemToWatch_3 = document.getElementById('label_03'),
          watcher_3 = scrollMonitor.create(scrollElemToWatch_3, -100),
          rev3 = new RevealFx(scrollElemToWatch_3, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 300,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              },
              direction: 'rl'
            }
          })
        watcher_3.enterViewport(function () {
          rev3.reveal();
          watcher_3.destroy();
        });
      }

      setTimeout(label_04, 10);
      function label_04() {
        var scrollElemToWatch_4 = document.getElementById('label_04'),
          watcher_4 = scrollMonitor.create(scrollElemToWatch_4, -100),
          rev4 = new RevealFx(scrollElemToWatch_4, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 300,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_4.enterViewport(function () {
          rev4.reveal();
          watcher_4.destroy();
        });
      }

      setTimeout(para_01, 10);
      function para_01() {
        var scrollElemToWatch_1 = document.getElementById('para_01'),
          watcher_1 = scrollMonitor.create(scrollElemToWatch_1, -100),
          rev1 = new RevealFx(scrollElemToWatch_1, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 500,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_1.enterViewport(function () {
          rev1.reveal();
          watcher_1.destroy();
        });
      }

      setTimeout(para_02, 10);
      function para_02() {
        var scrollElemToWatch_2 = document.getElementById('para_02'),
          watcher_2 = scrollMonitor.create(scrollElemToWatch_2, -100),
          rev2 = new RevealFx(scrollElemToWatch_2, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 500,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_2.enterViewport(function () {
          rev2.reveal();
          watcher_2.destroy();
        });
      }

      setTimeout(para_03, 10);
      function para_03() {
        var scrollElemToWatch_3 = document.getElementById('para_03'),
          watcher_3 = scrollMonitor.create(scrollElemToWatch_3, -100),
          rev3 = new RevealFx(scrollElemToWatch_3, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 500,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              },
              direction: 'rl'
            }
          })
        watcher_3.enterViewport(function () {
          rev3.reveal();
          watcher_3.destroy();
        });
      }

      setTimeout(para_04, 10);
      function para_04() {
        var scrollElemToWatch_4 = document.getElementById('para_04'),
          watcher_4 = scrollMonitor.create(scrollElemToWatch_4, -100),
          rev4 = new RevealFx(scrollElemToWatch_4, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 500,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_4.enterViewport(function () {
          rev4.reveal();
          watcher_4.destroy();
        });
      }

      setTimeout(para_05, 10);
      function para_05() {
        var scrollElemToWatch_5 = document.getElementById('para_05'),
          watcher_5 = scrollMonitor.create(scrollElemToWatch_5, -100),
          rev5 = new RevealFx(scrollElemToWatch_5, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 500,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_5.enterViewport(function () {
          rev5.reveal();
          watcher_5.destroy();
        });
      }

      setTimeout(para_06, 10);
      function para_06() {
        var scrollElemToWatch_6 = document.getElementById('para_06'),
          watcher_6 = scrollMonitor.create(scrollElemToWatch_6, -100),
          rev6 = new RevealFx(scrollElemToWatch_6, {
            revealSettings: {
              bgcolor: '#c9161e',
              duration: 500,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_6.enterViewport(function () {
          rev6.reveal();
          watcher_6.destroy();
        });
      }

      setTimeout(bg_01, 10);
      function bg_01() {
        var scrollElemToWatch_1 = document.getElementById('bg_01'),
          watcher_1 = scrollMonitor.create(scrollElemToWatch_1, -100),
          rev1 = new RevealFx(scrollElemToWatch_1, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 500,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_1.enterViewport(function () {
          rev1.reveal();
          watcher_1.destroy();
        });
      }

      setTimeout(bg_02, 10);
      function bg_02() {
        var scrollElemToWatch_2 = document.getElementById('bg_02'),
          watcher_2 = scrollMonitor.create(scrollElemToWatch_2, -100),
          rev2 = new RevealFx(scrollElemToWatch_2, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 500,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              },
              direction: 'rl'
            }
          })
        watcher_2.enterViewport(function () {
          rev2.reveal();
          watcher_2.destroy();
        });
      }

      setTimeout(bg_03, 10);
      function bg_03() {
        var scrollElemToWatch_3 = document.getElementById('bg_03'),
          watcher_3 = scrollMonitor.create(scrollElemToWatch_3, -100),
          rev3 = new RevealFx(scrollElemToWatch_3, {
            revealSettings: {
              bgcolor: '#c9151e',
              duration: 500,
              onStart: function (contentEl, revealerEl) {
                anime.remove(contentEl);
                contentEl.style.opacity = 0;
              },
              onCover: function (contentEl, revealerEl) {
                contentEl.style.opacity = 1;
                anime({
                  targets: contentEl,
                  duration: 100,
                  delay: 10,
                  easing: 'easeOutExpo',
                  opacity: [0, 1]
                });
              }
            }
          })
        watcher_3.enterViewport(function () {
          rev3.reveal();
          watcher_3.destroy();
        });
      }
    })();
  }
}
