import lottie from 'lottie-web/build/player/lottie.min.js';

export default class Lottie {
    constructor() {
        $(function(){
 
            var loader = document.getElementsByClassName("logo_anim");
             
            function loadAnimation(loader) {
                var animation = lottie.loadAnimation({
                    container: loader,
                    renderer: "svg",
                    loop: false,
                    autoplay: false,
                    path: "/_assets/json/logo.json"
                });
                loader.addEventListener("mouseenter", function() {
                    animation.stop();
		            animation.play();
                });
            }
             
            for (var i = 0; i < loader.length; i++) {
                loadAnimation(loader[i]);
            }
             
            });
    }
}