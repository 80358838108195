export default class sales {
  constructor() {
    $(function(){
      $('#content_old').hide();
      $('#btn_new').on('click',function(){
        $('#content_old').fadeOut(300);
        $('#content_new').fadeIn(300);
        $('.btn').addClass("open_new");
        $('.btn').removeClass("open_old");
        $('#btn_new').css('color','black');
        $('#btn_old').css('color','gray');
      });
      $('#btn_old').on('click',function(){
        $('#content_new').fadeOut(300);
        $('#content_old').fadeIn(300);
        $('.btn').addClass("open_old");
        $('.btn').removeClass("open_new");
        $('#btn_old').css('color','black');
        $('#btn_new').css('color','gray');
      });

      window.onload = function () {
        if("#old" == location.hash) {
          $('#content_new').fadeOut(300);
          $('#content_old').fadeIn(300);
          $('.btn').addClass("open_old");
          $('.btn').removeClass("open_new");
          $('#btn_old').css('color','black');
          $('#btn_new').css('color','gray');
        }else{
          $('#content_old').fadeOut(300);
          $('#content_new').fadeIn(300);
          $('.btn').addClass("open_new");
          $('.btn').removeClass("open_old");
          $('#btn_new').css('color','black');
          $('#btn_old').css('color','gray');
        };
      };
      window.onhashchange = function () {
        if("#old" == location.hash) {
          $('#content_new').fadeOut(300);
          $('#content_old').fadeIn(300);
          $('.btn').addClass("open_old");
          $('.btn').removeClass("open_new");
          $('#btn_old').css('color','black');
          $('#btn_new').css('color','gray');
        }else{
          $('#content_old').fadeOut(300);
          $('#content_new').fadeIn(300);
          $('.btn').addClass("open_new");
          $('.btn').removeClass("open_old");
          $('#btn_new').css('color','black');
          $('#btn_old').css('color','gray');
        };
      };
    });
    }
}


