export default class store {
  constructor() {
    $(function(){
      $('.anc').hover(
        function(){  
          $(this).next().addClass('arrow_hov');
        },
        function(){
          $(this).next().removeClass('arrow_hov');
        }
    );
    });
    }
}


