export default class otherHeader {
  constructor() {
    $(function () {
      var startPos = 0;
      var fixedHeader = $('#fixed-header');
      var innerHeader = $('#inner-header');
      var menu = $('.menu .menu_link');
      var threshold = 100;

      $(window).on('scroll', function () {
        var currentPos = $(this).scrollTop();
        if (currentPos > startPos) {
          if ($(window).scrollTop() >= threshold) {
            fixedHeader.css({
              'transform': 'translateY(-100px)'
            });
          }
        } else {
          fixedHeader.css({
            'transform': 'translateY(0px)'
          });
        }
        startPos = currentPos;
      });

      $('.acBtn').hover(function () {
        menu.css({
          'color': '#c9151e'
        });
        $(this).children(".menu_link").addClass("underbar");
        innerHeader.css({
          'background': 'white'
        });
        $(this).children('.acMenu').stop(true, true).delay(100).slideDown(200);

      }, function () {

        menu.css({
          'color': ''
        });
        $(this).children(".menu_link").removeClass("underbar");
        innerHeader.css({
          'background': ''
        });
        $(this).children('.acMenu').stop(true, true).delay(100).slideUp(200);
      });

      $('.acBox').hover(function () {
        $(this).addClass("underbar");
      } , function () {
        $(this).removeClass("underbar");
      });
      
    });
  }
}
